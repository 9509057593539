<template>
  <!-- <v-navigation-drawer v-model="drawer" app :mini-variant.sync="mini" mini-variant-width="100"> -->
  <v-navigation-drawer
    app
    permanent
    v-model="drawer"
    :mini-variant.sync="mini"
    expand-on-hover
  >
    <v-list-item>
      <v-icon color="white" @click="mini = !mini">mdi-menu</v-icon>
    </v-list-item>

    <v-divider></v-divider>
    <v-list dense shaped nav>
      <template v-for="(item, index) in links">
        <v-list-group
          color="white"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          v-if="
            $store.state.users.user &&
            item.permission.includes($store.state.users.user.role)
          "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title color="white" class="small-font">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-slot:prependIcon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.to"
          >
            <v-list-item-icon>
              <v-icon color="white">{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="small-font">{{
              child.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider
          v-if="item.sectionEnd"
          :key="index + 100"
          class="my-1"
        ></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      mini: true,
      links: [
        {
          title: "General",
          icon: "mdi-home-outline",
          permission: ["user", "admin"],
          items: [
            { title: "Home", to: { name: "Home" }, icon: "mdi-home" },
            // { title: "Under Development", to: { name: "UnderDev" }, icon: "mdi-screwdriver" },
          ],
          sectionEnd: true,
        },
        // {
        //   title: "Pricing Engine",
        //   icon: "mdi-tag-outline",
        //   permission: ["user", "admin"],
        //   items: [
        //     {
        //       title: "Calculator",
        //       to: { name: "Calculator" },
        //       icon: "mdi-calculator",
        //     },
        //   ],
        //   sectionEnd: true,
        // },

        // {
        //   title: "Marketing",
        //   icon: "mdi-message-text-outline",
        //   sectionEnd: true,
        //   permission: ["admin"],
        //   items: [],
        // },
        // {
        //   title: "Clients",
        //   icon: "mdi-email-outline",
        //   sectionEnd: true,
        //   permission: ["admin"],
        //   items: [],
        // },
        {
          title: "Profiler",
          icon: "mdi-account-multiple-outline",
          sectionEnd: true,
          permission: ["admin","user"],
          items: [
            {
              title: "Setup Linkedin Account",
              to: "/dashboard/accountsetup",
              icon: "mdi-cog",
            },
            {
              title: "Setup Cache",
              to: "/dashboard/account-setup-for-caching",
              icon: "mdi-cog",
            },
            {
              title: "Search & Scoring",
              to: "/dashboard/scoring",
              icon: "mdi-account-multiple-plus",
            },
            {
              title: "Scoring History",
              to: "/dashboard/scoringhistory",
              icon: "mdi-message-text-outline",
            },
          ],
        },
        {
          title: "Users",
          icon: "mdi-account-multiple-outline",
          sectionEnd: true,
          permission: ["admin"],
          items: [
            {
              title: "Manage Users",
              to: "/dashboard/users/manageusers/changeusersettings",
              icon: "mdi-account-multiple-plus",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
* {
  color: white;
}
.group-heading {
  /* background-color: #dfedff; */
  /* margin-bottom: 5px; */
  margin-top: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-heading-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.group-heading-text {
  font-weight: 700 !important;
}
.v-icon.v-icon {
  color: black;
}
::v-deep .v-label,
::v-deep .small-font,
::v-deep .small-font *,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .v-list-group__header__append-icon i {
  color: white;
}
::v-deep .v-list-group__header__prepend-icon {
  margin-right: 12px !important;
}
</style>
